<template>
  <div>
    <div class="container">
      <InfoBox
        v-if="marketingMessage"
        :icon="iconThumbsUp"
        variant="info"
      >
        {{ marketingMessage }}
      </InfoBox>
      <div v-if="!editMode">
        <BaseButton
          size="small"
          variant="secondary"
          :style="{ marginBottom: '15px' }"
          @click="editModeStart(true)"
        >
          Upraviť údaje
        </BaseButton>
        <DescriptionList
          :items="vehicleTableData"
        />
      </div>

      <div v-else>
        <FormGroup
          label="Značka"
          :rules="['required']"
          ref="vehicleForm"
        >
          <InputSelect v-model="vehicle.make" :options="optionsMakes" search />
        </FormGroup>
        <FormGroup
          label="Model"
          :rules="['required']"
        >
          <InputSelect v-model="vehicle.model" :options="optionsModels" search />
        </FormGroup>
        <FormGroup
          label="Zdvihový objem valcov"
          :rules="['required', positiveNumberRule]"
          :tooltipText="tooltips.engineDisplacement"
        >
          <InputGroup suffix="cm³">
            <InputText v-model.number="vehicle.engineDisplacement" inputmode="numeric" />
          </InputGroup>
        </FormGroup>
        <FormGroup
          label="Výkon motora"
          :rules="['required', positiveNumberRule]"
          :tooltipText="tooltips.enginePower"
        >
          <InputGroup suffix="kW">
            <InputText v-model.number="vehicle.enginePower" inputmode="numeric" />
          </InputGroup>
        </FormGroup>
        <FormGroup
          label="Rok výroby"
          :rules="[
            'required',
            $v.rules.year({ minAge: 0, maxAge: 120, message: 'Zadajte korektný rok.' })
          ]"
          :tooltipText="tooltips.yearOfManufacture"
        >
          <InputText v-model.number="vehicle.yearOfManufacture" inputmode="numeric" />
        </FormGroup>
        <FormGroup
          label="Hmotnosť vozidla"
          :rules="['required', positiveNumberRule]"
          :tooltipText="tooltips.weight"
        >
          <InputGroup suffix="kg">
            <InputText v-model.number="vehicle.weight" inputmode="numeric" />
          </InputGroup>
        </FormGroup>
        <FormGroup
          label="Počet miest na sedenie"
          :rules="[
            'required',
            positiveNumberRule,
            $v.rules.max({ compare: 10 })
          ]"
        >
          <InputText v-model="vehicle.seatCount" inputmode="numeric" />
        </FormGroup>
        <FormGroup
          label="Séria a číslo TP (nepovinné)"
          :tooltipText="tooltips.registrationNumber"
        >
          <InputText v-model="vehicle.registrationNumber" autocapitalize="characters" />
        </FormGroup>
        <FormGroup
          label="VIN (nepovinné)"
          :rules="['vinNumber']"
          :tooltipText="tooltips.vin"
        >
          <InputText v-model="vehicle.vin" autocapitalize="characters" />
        </FormGroup>
        <FormGroup
          label="Farba (nepovinné)"
          :tooltipText="tooltips.color"
        >
          <InputText v-model="vehicle.color" />
        </FormGroup>
      </div>
    </div>

    <BaseHeading size="small" id="o-aute">
      Údaje o aute
    </BaseHeading>
    <div class="container">
      <FormGroup
        label="Kategória vozidla"
        tooltipText="Úžitkové je vozidlo do 3,5 tony, ktoré má max. 3 miesta na sedenie a je zaradené v kategórii N1 v technickom preukaze."
        :rules="['requiredOption']"
      >
        <InputOptions
          :options="optionsVehicleCategory"
          v-model="vehicle.commercial"
          variant="iconlarge"
        />
      </FormGroup>

      <FormGroup
        label="Je vozidlo na lízing alebo na úver?"
        :rules="['requiredOption']"
      >
        <InputOptions
          :options="optionsYN"
          v-model="vehicle.leased"
        />
      </FormGroup>

      <FormGroup
        label="Je vozidlo určené na bežné použitie?"
        tooltipText="Online môžete poistiť iba vozidlo určené na bežné použitie. Ak chcete poistiť iné vozidlo (napr. taxi, historické vozidlo, vozidlo autoškoly a pod.), prosím, obráťte sa na naše kontaktné centrum – 02/38 11 11 16."
        :rules="[
          'requiredOption',
          $v.rules.not({ compare: 'NONSTANDARD', message: 'Ak chcete poistiť iné vozidlo (napr. taxi, historické vozidlo, vozidlo autoškoly a pod.), prosím, obráťte sa na naše kontaktné centrum – 02/38 11 11 16.' })
        ]"
      >
        <InputOptions
          :options="optionsUsageType"
          v-model="vehicle.usageType"
        />
      </FormGroup>

      <FormGroup
        label="Spôsobili ste nehodu za posledné 3 roky?"
        tooltipText="Ide o vami zavinenú škodovú udalosť, ktorú uhrádzala vaša poisťovňa z vášho PZP alebo havarijného poistenia."
        :rules="['requiredOption']"
        :afterMessageList="[
          { for: true, message: null },
          { for: false, message: 'Výborne, zodpovedných vodičov odmeňujeme lepšou cenou! Môžete získať bonus až 60 %.' },
        ]"
        :afterMessageActive="productProps.holderCausedDamage"
      >
        <InputOptions
          :options="optionsYN"
          v-model="productProps.holderCausedDamage"
        />
      </FormGroup>

    </div>
  </div>
</template>

<script>
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import vehiclePersonal from '@/assets/icons/vehiclePersonal';
import vehicleCommercial from '@/assets/icons/vehicleCommercial';

import GET_MAKES from '@/graphql/GetMakes.gql';
import GET_MODELS from '@/graphql/GetModels.gql';
import scrollToElement from '@gds/util/scrollToElement';
import transformCodelist from '@gds/util/transformCodelist';
import capitalize from '@gds/util/capitalize';
import sortMakes from '@/util/sortMakes';

import BaseHeading from '@gds/components/BaseHeading';
import InfoBox from '@gds/components/InfoBox';
import DescriptionList from '@gds/components/DescriptionList';
import BaseButton from '@gds/components/BaseButton';
import FormGroup from '@gds/components/FormGroup';
import InputText from '@gds/components/InputText';
import InputSelect from '@gds/components/InputSelect';
import InputOptions from '@gds/components/InputOptions';
import InputGroup from '@gds/components/InputGroup';

import { mapGetters } from 'vuex';

export default {
  components: {
    BaseHeading,
    InfoBox,
    DescriptionList,
    BaseButton,
    FormGroup,
    InputText,
    InputSelect,
    InputOptions,
    InputGroup,
  },
  data() {
    return {
      iconThumbsUp: faThumbsUp,
      marketingMessage: null,
      editMode: false,
      positiveNumberRule: this.$v.rules.min({
        compare: 0,
        message: 'Zadajte platnú hodnotu',
      }),
      makesCodelist: [],
      modelsCodelist: [],
      optionsVehicleCategory: [
        { value: false, label: 'Osobné', icon: vehiclePersonal },
        { value: true, label: 'Úžitkové do 3,5 t', icon: vehicleCommercial },
      ],
      optionsUsageType: [
        { value: 'STANDARD', label: 'Áno' },
        { value: 'NONSTANDARD', label: 'Nie' },
      ],
      optionsYN: [
        { value: true, label: 'Áno' },
        { value: false, label: 'Nie' },
      ],
    };
  },
  apollo: {
    makesCodelist: {
      query: GET_MAKES,
      update(data) {
        this.vehicle.makeMap = {};
        const { makeMap } = this.vehicle;
        // eslint-disable-next-line no-param-reassign
        data.makesCodelist.forEach((make) => {
          makeMap[make.key] = make.value;
        });
        return data.makesCodelist;
      },
    },
    modelsCodelist: {
      query: GET_MODELS,
      variables() {
        return {
          make: this.vehicle.make,
        };
      },
      update(data) {
        this.vehicle.modelMap = {};
        const { modelMap } = this.vehicle;
        // eslint-disable-next-line no-param-reassign
        data.modelsCodelist.forEach((model) => {
          modelMap[model.key] = model.value;
        });
        return data.modelsCodelist;
      },
    },
  },
  computed: {
    ...mapGetters([
      'vehicle',
      'productProps',
      'shouldPrefilledOfferFastForward',
    ]),
    tooltips: () => ({
      engineDisplacement: 'Nájdete v technickom preukaze pod P.1',
      enginePower: 'Nájdete v technickom preukaze pod P.2',
      yearOfManufacture: 'Nájdete v technickom preukaze pod B – dátum prvej evidencie vozidla',
      weight: 'Nájdete v technickom preukaze pod F.1 - najväčšia prípustná celková hmotnosť',
      setCount: 'Nájdete v technickom preukaze pod S.1',
      registrationNumber: 'Číslo technického preukazu, ktoré sa nachádza na jeho prvej strane vo formáte AB 123456',
      vin: 'Nájdete v technickom preukaze pod E',
      color: 'Nájdete v technickom preukaze pod R',
    }),
    vehicleTableData() {
      const { vehicle, tooltips } = this;
      return [
        {
          label: 'Značka',
          value: vehicle.makeMap[vehicle.make],
          onEdit: this.handleEdit,
        },
        {
          label: 'Model',
          value: vehicle.modelMap[vehicle.model],
          onEdit: this.handleEdit,
        },
        {
          label: 'Zdvihový objem valcov',
          value: `${vehicle.engineDisplacement} cm³`,
          tooltipText: tooltips.engineDisplacement,
          onEdit: this.handleEdit,
        },
        {
          label: 'Výkon motora',
          value: `${vehicle.enginePower} kW`,
          tooltipText: tooltips.enginePower,
          onEdit: this.handleEdit,
        },
        {
          label: 'Rok výroby',
          value: vehicle.yearOfManufacture,
          tooltipText: tooltips.yearOfManufacture,
          onEdit: this.handleEdit,
        },
        {
          label: 'Hmotnosť vozidla',
          value: `${vehicle.weight} kg`,
          tooltipText: tooltips.weight,
          onEdit: this.handleEdit,
        },
        {
          label: 'Počet miest na sedenie',
          value: vehicle.seatCount,
          tooltipText: tooltips.seatCount,
          onEdit: this.handleEdit,
        },
        {
          label: 'Séria a číslo technického preukazu',
          value: vehicle.registrationNumber,
          tooltipText: tooltips.registrationNumber,
          onEdit: this.handleEdit,
        },
        {
          label: 'VIN',
          value: vehicle.vin,
          tooltipText: tooltips.vin,
          onEdit: this.handleEdit,
        },
        {
          label: 'Farba',
          value: vehicle.color,
          tooltipText: tooltips.color,
          onEdit: this.handleEdit,
        },
      ];
    },
    optionsMakes() {
      if (this.makesCodelist) {
        const topMakes = ['Škoda', 'Volkswagen', 'Hyundai', 'Peugeot', 'Ford', 'Renault', 'Opel', 'Citroen'];
        const topMakesLastElement = topMakes[topMakes.length - 1];

        const transformed = transformCodelist(this.makesCodelist);
        transformed.sort(sortMakes(topMakes));
        const detached = transformed.map((item) => ({
          ...item,
          detached: item.label === topMakesLastElement,
        }));

        return [
          { value: null, label: 'Prosím, vyberte' },
          ...detached,
        ];
      }
      return [];
    },
    optionsModels() {
      if (this.modelsCodelist) {
        const transformed = transformCodelist(this.modelsCodelist, {
          makeCapitalize: true,
          toNumber: true,
        });
        return [
          { value: null, label: 'Prosím, vyberte' },
          ...transformed,
        ];
      }
      return [];
    },
  },
  methods: {
    generateMarketingMessage(make) {
      if (!make) {
        this.marketingMessage = null;
      }
      const insuredCount = Math.round(Math.random() * 100);
      const makeCapitalized = capitalize(make);
      this.marketingMessage = `Skvelé, za posledný mesiac sa u nás poistilo už ${insuredCount} majiteľov áut značky ${makeCapitalized}. Aj vy k nim môžete patriť!`;
    },
    editModeStart(doScroll) {
      this.marketingMessage = false;
      this.editMode = true;
      if (doScroll) {
        this.$nextTick(() => {
          scrollToElement({
            element: this.$refs.vehicleForm.$el,
            offset: 10,
          });
        });
      }
    },
    editModeEnd() {
      if (this.$v.validator.check()) {
        this.editMode = false;
      }
    },
    handleEdit() {
      this.editModeStart(true);
    },
  },
  mounted() {
    const { vehicle } = this;
    // if (vehicle.make) {
    //   this.generateMarketingMessage(vehicle.makeMap[vehicle.make]);
    // }
    if (!vehicle.make || !vehicle.model) {
      this.editModeStart();
    } else if (this.shouldPrefilledOfferFastForward) {
      this.$v.validator.checkAndContinue(this);
    }
  },
};
</script>
