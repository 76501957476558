/* eslint-disable */
'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'generali-vehicle-commercial';
var width = 640;
var height = 512;
var ligatures = [];
var unicode = 'f640';
var svgPathData = 'M480 96l16.3.1 15.4.3 21.2.7 12.9.8 11.6.8 5.4.5 9.7 1.1 8.4 1.3c9 1.5 15 3.3 17.3 5.3 4.7 3.8 7.3 18.2 8.6 37.9l.6 12.4.4 13.4.2 21.4v64l32 35.6V352H536.3a64 64 0 01-112.6 0H184.3a64 64 0 01-112.6 0H64c-24.2 0-45-8.4-62.3-25.3-3.3-29.7-1.8-51.8 4.6-66.4 9.5-21.7 29-46.4 62-52.3l80.2-14 11.5-2c53.2-44.5 101.2-71.7 144-81.6 48.2-11 87-13.6 130.5-14.2l29.8-.2H480zM128 289.6a32 32 0 100 64 32 32 0 000-64zm352 0a32 32 0 100 64 32 32 0 000-64zM491.3 128l-52.8.1-26 .6-17.9.9c-3 .1-5.9.3-8.8.6L365.1 224H163l-89 15.5c-17.1 3-30.4 15.6-38.3 33.7-2.6 5.8-3.9 16-3.6 29.9l.4 7.5.4.3c7.6 5.2 16 8 25.4 8.9l5.8.2a64 64 0 01128 0h224a64 64 0 01128 0h64v-16l-32-35.7v-84.5l-.2-15-.5-13.2-.6-11.3-.8-8.7-.4-2.8-6.1-.9-10.2-1c-5-.5-10.4-1-16.2-1.3l-18.4-.9c-9.8-.3-20.3-.6-31.3-.7zm-180.1 13.6c-29.2 6.7-62.5 23.4-99.4 50.4h127l13.3-58.1a501 501 0 00-41 7.7z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.default = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
