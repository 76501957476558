<template>
  <div
    class="collapsible-space"
    :style="{
      height: `${height}`,
      transitionDuration: `${duration}s`,
    }"
  >
    <div
      :class="innerClass"
      ref="inner"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import './CollapsibleSpace.scss';

export default {
  name: 'CollapsibleSpace',
  data() {
    return {
      height: 0,
      autoHeightInterval: null,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 0.2,
    },
    innerClass: {
      type: [String, Object],
      default: 'inner',
    },
  },
  methods: {
    setOpen() {
      const { inner } = this.$refs;
      const durationMiliseconds = this.duration * 1000;
      this.height = `${inner.offsetHeight}px`;
      this.autoHeightInterval = setTimeout(() => {
        this.height = 'auto';
      }, durationMiliseconds);
    },
    setClosed() {
      const { inner } = this.$refs;
      clearInterval(this.autoHeightInterval);
      this.height = `${inner.offsetHeight}px`;
      this.$nextTick(() => {
        this.height = 0;
      });
    },
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.setOpen();
      } else {
        this.setClosed();
      }
    },
  },
  mounted() {
    // Open spaces without animation when initialized as open
    if (this.isOpen) {
      this.height = 'auto';
    }
  },
};
</script>
