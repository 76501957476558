const sortMakes = (topMakes) => (a, b) => {
  if (topMakes.includes(a.label) && topMakes.includes(b.label)) {
    if (topMakes.indexOf(a.label) < topMakes.indexOf(b.label)) {
      return -1;
    }
    return 1;
  }

  if (topMakes.includes(a.label)) {
    return -1;
  }
  if (topMakes.includes(b.label)) {
    return 1;
  }
  return a.label.localeCompare(b.label);
};

export default sortMakes;
