<template>
  <div
    class="description-list-item"
  >
    <div class="labelcol">
      <div class="label">
        {{ label }}
      </div>

    </div>
    <div class="valuecol">
      <span v-html="stripTagsExcludeBr(value)" />
    </div>
    <div class="actions">
      <TooltipSet
        v-if="tooltipText"
        buttonSize="small"
      >
        {{ tooltipText }}
      </TooltipSet>
      <CopyButton
        v-if="isCopyable"
        :value="valueToCopy || String(value)"
      />
      <InterfaceIcon
        v-if="isEditable"
        element="button"
        icon="pencil"
        @click="onEdit"
      />
      <InterfaceIcon
        v-if="isExpandable"
        element="button"
        icon="caret"
        @click="toggleExpansion()"
        :isFlipped="isExpanded"
      />
    </div>
    <CollapsibleSpace
      v-if="isExpandable"
      :isOpen="isExpanded"
      class="expansion-container"
      innerClass="expansion"
    >
      <span v-html="stripTagsExcludeBr(valueExpanded)"></span>
    </CollapsibleSpace>
  </div>
</template>

<script>
import { stripTagsExcludeBr } from '@gds/util/stripTagsExcludeBr';
import InterfaceIcon from '@gds/components/InterfaceIcon';
import TooltipSet from '@gds/components/TooltipSet';
import CollapsibleSpace from '@gds/components/CollapsibleSpace';
import CopyButton from './CopyButton.vue';

export default {
  components: {
    InterfaceIcon,
    TooltipSet,
    CollapsibleSpace,
    CopyButton,
  },
  props: {
    label: String,
    value: [String, Number],
    isCopyable: Boolean,
    valueToCopy: String,
    valueExpanded: String,
    tooltipText: String,
    onEdit: Function,
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    isExpandable() {
      return !!this.valueExpanded;
    },
    isEditable() {
      return !!this.onEdit;
    },
  },
  methods: {
    toggleExpansion() {
      if (this.isExpandable) {
        this.isExpanded = !this.isExpanded;
      }
    },
    stripTagsExcludeBr,
  },
};
</script>
