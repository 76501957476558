/* eslint-disable */
'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'generali-vehicle-personal';
var width = 640;
var height = 512;
var ligatures = [];
var unicode = 'f640';
var svgPathData = 'M493.8 154.8l11.8 4.6 5.3 2.2c25.7 11 65.3 19.7 119 25.7v84.4c12 24 12 41.5 0 52.4-8 7-27.5 13.6-58.6 19.8l-15.2 2.9-8.2 1.4-11.6 1.8a64 64 0 01-112.8 3.6H182.3a64 64 0 01-110.8 0H30.9c-15.6.7-25.3-4.1-29-14.4-5.6-15.3 1.6-85.7 16.2-99.2 1.7-1.6 4.4-3.2 8-5l6.3-2.6 3.7-1.3 8.4-2.8 4.8-1.5 10.7-3 12.1-3 21-5 16-3.3 26.5-5.3 9.6-1.8c67.6-45.3 125.6-70.5 173.7-75.8 62.8-6.9 132.4 9.8 174.9 25.2zM126.9 289.6a32 32 0 100 64 32 32 0 000-64zm352 0a32 32 0 100 64 32 32 0 000-64zm-60.6-122.5l-43 65.6-239 7-1.2.2-22 4.5-25.5 5.6-15.8 3.8-16.5 4.3-6 1.8-4.7 1.5-3.2 1.2-1.4.8h-.1v.9l-2.4 10.6-2 10.5-1.3 7.9c-.6 3.9-1 7.8-1.3 11.5l-.7 10.5-.3 6.3h31v-1a64 64 0 01128 1h224v-1a64 64 0 01127.8-4l11.2-2 12.8-2.4 11.3-2.4 9.6-2.2 4.2-1.1 7-2 5.3-2c1.5-.5 2.6-1.1 3.4-1.6l.8-.6h-.3l-.6-1-3-6.8-3.2-6.4-3.4-6.8v-63.8l-6-.9c-36-5.4-65-12.3-87.1-21l-6.5-2.6a394 394 0 00-79.9-24zm-95.9-5.7c-32.7 3.6-72 18.3-117.7 44.6l153-4.8 25.8-39.3a292.2 292.2 0 00-61.1-.5z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.default = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
