<template>
  <button
    class="copy-button"
    @click="copy()"
  >
    <InterfaceIcon
      class="icon"
      icon="copy"
    />
    <input type="hidden" ref="copyElement">
  </button>
</template>

<script>
import InterfaceIcon from '@gds/components/InterfaceIcon/';

export default {
  components: {
    InterfaceIcon,
  },
  props: {
    value: String,
  },
  methods: {
    copy() {
      const input = this.$refs.copyElement;
      input.value = this.value;
      input.type = 'text';
      input.select();
      input.setSelectionRange(0, 99999);
      document.execCommand('copy');
      input.type = 'hidden';
    },
  },
};
</script>
