<template>
  <div class="description-list">
    <DescriptionListItem
      v-for="(item, i) in items"
      :key="i"
      :label="item.label"
      :value="item.value"
      :valueExpanded="item.valueExpanded"
      :valueToCopy="item.valueToCopy"
      :isCopyable="item.isCopyable"
      :onEdit="item.onEdit"
      :tooltipText="item.tooltipText"
    />
  </div>
</template>

<script>
import './DescriptionList.scss';
import DescriptionListItem from './components/DescriptionListItem.vue';

// TODO: Use DD / DT markup
export default {
  name: 'DescriptionList',
  components: {
    DescriptionListItem,
  },
  props: {
    items: Array,
    // label, value, isCopyable, valueToCopy
  },
};
</script>
